import { axios } from '@/utils/request'

/**
 * @description:添加模型
 * @Author: awenyjw
 * @Date: 2023-05-19 10:50:56
 */
export function _addModelManagement(params) {
    return axios({
        url: '/ModelManagement/add',
        method: 'post',
        data: params
    })
}


/**
 * @description:修改模型
 * @Author: awenyjw
 * @Date: 2023-05-19 10:50:56
 */
export function _editModelManagement(params) {
    return axios({
        url: '/ModelManagement/edit',
        method: 'post',
        data: params
    })
}


/**
 * @description:删除模型
 * @Author: awenyjw
 * @Date: 2023-05-19 10:50:56
 */
export function _deleteModelManagement(params) {
    return axios({
        url: '/ModelManagement/delete',
        method: 'post',
        data: params
    })
}


/**
 * @description:获取分页
 * @Author: awenyjw
 * @Date: 2023-05-19 10:50:56
 */
export function _pageModelManagement(params) {
    return axios({
        url: '/ModelManagement/page',
        method: 'get',
        params
    })
}

/**
 * @description:获取详情
 * @Author: awenyjw
 * @Date: 2023-05-19 10:50:56
 */
export function _detailModelManagement(params) {
    return axios({
        url: `/ModelManagement/detail/${params.id}`,
        method: 'get',
    })
}




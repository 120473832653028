<template>
  <div>
    <el-drawer
      :before-close="clearDrawerContent"
      :visible.sync="drawer"
      size="90%"
      :with-header="false"
      :destroy-on-close="true"
      :close-on-press-escape="false"
      :wrapperClosable="false"
    >
      <div class="item">
        <div class="flex-between" style="align-items: center;">
          <templateTitle title="基本信息" :isBack="false"></templateTitle>
          <el-button type="text"><i class="el-icon-close closebtn" @click="btnClose"></i></el-button>
        </div>
        <el-form :model="form" :rules="rules" ref="form" label-width="130px" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item label="所属公司" prop="tenantId">
                <el-select
                  :disabled="isView"
                  v-model="form.tenantId"
                  filterable
                  placeholder="请输入"
                  @keyup.native="handleInput"
                  @change="handleTenantChange"
                >
                  <el-option v-for="item in tenantArr" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="form.tenantId">
              <el-form-item label="所属项目" prop="projectId">
                <el-select :disabled="isView" v-model="form.projectId" filterable placeholder="请选择">
                  <el-option v-for="item in projectArr" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="模型名称" prop="name">
                <el-input
                  :disabled="isView"
                  v-model="form.name"
                  placeholder="请输入"
                  type="input"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="模型地址" prop="url">
                <el-input
                  :disabled="isView"
                  v-model="form.url"
                  placeholder="请输入"
                  type="input"
                  maxlength="200"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-button size="small" type="primary" @click="loadModel">加载模型</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="map-box">
        <div
          v-if="Object.keys(viewer).length != 0 && form.wallsDto.length"
          style="position: absolute;left: 10px;top: 10px;z-index:99999999;"
        >
          <el-card>
            <div slot="header" class="clearfix">
              <span style=" font-size: 16px;font-weight: 700;">区域信息</span>
            </div>
            <div class="modelmag-container">
              <div
                :class="['item', { selected: item == selected }]"
                v-for="(item, index) in form.wallsDto"
                :key="index"
                @click="checkItem(item)"
              >
                <div class="flex-between">
                  <div style="flex: 3;">{{ `区域${index + 1}` }}</div>
                  <div style="flex: 1;" @click="handleDelete($event, index)">
                    <el-link size="mini" type="danger">删除</el-link>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <div v-if="Object.keys(viewer).length != 0" style="position: absolute;right: 10px;top: 10px;z-index:99999999;">
          <div class="flex">
            <div>
              <el-button size="small" type="primary" @click="startDraw">开始绘制</el-button>
              <el-button size="small" type="success" @click="downDraw">完成绘制</el-button>
            </div>
            <div style="margin-left: 20px;">
              <el-radio-group v-model="darwType" size="small" @input="raidoChange">
                <el-radio-button v-for="item in drawOptions" :label="item.name" :key="item.id">{{
                  item.name
                }}</el-radio-button>
              </el-radio-group>
            </div>
            <!-- <div style="margin-left: 5px;">
              <el-button size="small" type="info" @click="clearMakers">重置</el-button>
            </div> -->
          </div>
        </div>
        <div id="cesiumContainer" ref="cesiumContainer" style="position: relative"></div>
      </div>
      <div v-if="!isView" class="item">
        <el-row style="text-align: right;">
          <div class="dialog-footer">
            <el-button size="small" :loading="loading" class="title" @click="btnClose()">取消</el-button>
            <el-button size="small" type="primary" :loading="loading" class="title" @click="submitForm">保存</el-button>
          </div>
        </el-row>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import templateTitle from '@/components/templateTitle.vue'
import debounce from 'lodash/debounce'

import { _addModelManagement, _editModelManagement, _detailModelManagement } from '@/api/modular/system/model'
// import { _projectList } from '@/api/modular/supervision/projectMag/projectManager.js'
// import { _listProjectStorage } from '@/api/modular/projectManage/projectMgt/projectStorage.js'
import { _queryTenants } from '@/api/keepHome'

import _ from 'lodash'
// 声明实例：有回调函数
export default {
  name: 'HcSmartSupervisionModelMag',
  components: {
    templateTitle
  },
  data() {
    return {
      viewer: {},
      selected: [], // 默认未选中
      pointsArr: [],
      isView: false,
      drawer: false,
      form: {
        url: '',
        name: '',
        projectId: null,
        tenantId: null,
        wallsDto: [],
        linesDto: []
      },
      darwType: '墙体',
      drawOptions: [
        {
          id: 1,
          name: '墙体'
        },
        {
          id: 2,
          name: '线条'
        }
      ],
      loading: false,
      type: '',
      rules: {
        url: [{ required: true, message: '模型地址不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '模型名称不能为空', trigger: 'blur' }],
        projectId: [{ required: true, message: '所属项目不能为空', trigger: 'blur' }],
        tenantId: [{ required: true, message: '所属公司不能为空', trigger: 'blur' }]
      },
      proId: null,
      id: null,
      projectArr: [],
      tenantArr: [],
      searchValue: ''
    }
  },
  watch: {
    'form.url': {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue, 'newValue, oldValue')
      }
    }
  },
  mounted() {
    let originalArray = [
      { x: -1529626.8159897898, y: 5226763.6636416735, z: 3309482.061006816 },
      { x: -1529643.4615150082, y: 5226816.945956552, z: 3309378.8241406037 }
    ]

    let flatArray = originalArray.map(obj => [obj.x, obj.y, obj.z]).flat() // 使用展开运算符将对象的值展开到数组中

    console.log(flatArray)
  },
  methods: {
    checkArray(arr) {
      return arr.every(function(item) {
        return item.type === 1
      })
        ? 1
        : 2 // 如果所有项的type都为1，则返回1，否则返回2
    },
    drawWalls(points) {
      console.log(points, '遍历渲染+++')
      if (points && points.length) {
        let type = this.checkArray(points)
        if (type == 1) {
          setTimeout(() => {
            // new AnimationWall(window.viewer).add(points)
          }, 1000)
        } else if (type == 2) {
          this.drawLines(points)
        }
      }
    },
    drawLines(linesDto) {
      window.viewer.entities.add({
        polyline: {
          positions: linesDto.map(item => {
            return {
              x: item.x,
              y: item.y,
              z: item.z
            }
          }),
          // 宽度
          width: 5,
          // 线的颜色
          material: Cesium.Color.DEEPPINK,
          // 线的顺序,仅当`clampToGround`为true并且支持地形上的折线时才有效。
          zIndex: 10,
          // 显示在距相机的距离处的属性，多少区间内是可以显示的
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 1500),
          // 是否显示
          show: true
        }
      })
    },
    checkItem(item) {
      window.viewer.entities.removeAll()
      console.log(item, '******checkItem******')
      this.selected = item
      this.$nextTick(() => {
        if (item && item.length) {
          this.drawWalls(item) //绘制墙体
        }
      })
    },
    handleDelete(event, index) {
      event.stopPropagation()
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          console.log(event, 'handleDelete*****')
          this.form.wallsDto.splice(index, 1)
          console.log(this.form.wallsDto, 'this.form.wallsDto')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 比较俩数组是否相等
    deepEqual(a, b) {
      if (a === b) return true

      if (a == null || b == null) return false

      if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length) return false

        for (let i = 0; i < a.length; i++) {
          if (!this.deepEqual(a[i], b[i])) return false
        }

        return true
      }

      return false
    },
    startDraw() {
      this.pointsArr = []
      window.viewer.entities.removeAll()
    },
    downDraw() {
      if (this.darwType == '墙体') {
        if (this.pointsArr && this.pointsArr.length) {
          this.form.wallsDto.push(this.pointsArr.concat(this.pointsArr[0]))
        }
      } else if (this.darwType == '线条') {
        this.form.wallsDto.push(this.pointsArr)
      }
      console.log(this.form.wallsDto, 'this.form.wallsDtothis.form.wallsDto')
      this.pointsArr = []
      window.viewer.entities.removeAll()
    },
    raidoChange() {
      this.pointsArr = []
      window.viewer.entities.removeAll()
      // if (this.darwType == '墙体') {
      //   this.form.wallsDto = []
      // } else if (this.darwType == '线条') {
      //   this.form.linesDto = []
      // }
    },
    clearMakers() {
      if (this.darwType == '墙体') {
        this.form.wallsDto = []
      } else if (this.darwType == '线条') {
        this.form.linesDto = []
      }
      // window.viewer.entities.removeById(this.darwType)
      window.viewer.entities.removeAll()
    },
    addLine(pointsArr) {
      let points = pointsArr
        .map(cartesian => {
          // console.log(cartesian, 'cartesian222')
          let cartographic = Cesium.Cartographic.fromCartesian({
            x: cartesian.x,
            y: cartesian.y,
            z: cartesian.z
          })
          // console.log('坐标对象2222', {
          //   lng: Cesium.Math.toDegrees(cartographic.longitude),
          //   lat: Cesium.Math.toDegrees(cartographic.latitude),
          //   height: cartographic.height
          // })
          let lng = Cesium.Math.toDegrees(cartographic.longitude)
          let lat = Cesium.Math.toDegrees(cartographic.latitude)
          let height = cartographic.height
          return [lng, lat, height]
        })
        .flat()

      window.viewer.entities.add({
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArrayHeights(points),
          // 宽度
          width: 2,
          // 线的颜色
          material: Cesium.Color.WHITE,
          // 线的顺序,仅当`clampToGround`为true并且支持地形上的折线时才有效。
          zIndex: 10,
          // 显示在距相机的距离处的属性，多少区间内是可以显示的
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 1500),
          // 是否显示
          show: true
        }
      })
    },
    addMarker(makers, drawName) {
      // window.viewer.entities.removeAll()
      makers.forEach(element => {
        if (element) {
          const label = new Cesium.Entity({
            name: drawName,
            position: element,
            billboard: {
              image: '/maker.png',
              width: 37,
              height: 50,
              heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
            }
          })
          window.viewer.entities.add(label)
        }
      })
    },
    async initViewer() {
      Cesium.Ion.defaultAccessToken =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2OWRjYTg4NS02NzQwLTQ0ZTEtOTk2Yy1lNmE4M2IyN2Q2ZjYiLCJpZCI6MTM5NTk3LCJpYXQiOjE2ODQzOTIyMjB9.141McZH2a5ZUjmG_TNySRGkglB6jdhoehnmcGn9oC2o'
      window.viewer = new window.Cesium.Viewer('cesiumContainer', {
        timeline: false, //底部时间轴
        animation: false, //左下角仪表盘
        fullscreenButton: false, //全屏按钮
        navigationHelpButton: false, //帮助按钮
        homeButton: false,
        terrainProvider: Cesium.createWorldTerrain(),
        infoBox: false,
        navigationHelpButton: false,
        creditContainer: document.createElement('div'), //左下角logo
        sceneModePicker: false,
        geocoder: false,
        baseLayerPicker: false
      })
      this.loadTianMap(3)
      let viewer = window.viewer
      this.viewer = window.viewer
      let _this = this
      console.log(this.form.url, '后台url')
      let tileset = window.viewer.scene.primitives.add(
        new Cesium.Cesium3DTileset({
          // url: 'http://146.56.240.129:8001/hsskproject/hssk/tileset.json', //测试
          url: this.form.url, //测试
          modelMatrix: Cesium.Matrix4.fromArray([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]),
          baseScreenSpaceError: 1024,
          // //【重要】数值加大，能让最终成像变模糊
          skipScreenSpaceErrorFactor: 0.3,
          skipLevels: 0.2,
          preferLeaves: true,
          maximumScreenSpaceError: 2 //最大的屏幕空间误差 调整精度
        })
      )
      window.viewer.flyTo(tileset)
      // window.viewer.scene.globe.depthTestAgainstTerrain = true
      //==地租自转事件

      //   this.rotate(viewer, 0)
      //   this.initCamera()
      // const homeBtnViewModel = viewer.homeButton.viewModel
      // // 在 HomeButtonViewModel 的点击事件中调用清除数据方法
      // homeBtnViewModel.command.beforeExecute.addEventListener(clearData)
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
      // 右键获取坐标
      let pointsArr = []
      handler.setInputAction(function(movement) {
        var ray = viewer.camera.getPickRay(movement.position)
        var cartesian = viewer.scene.globe.pick(ray, viewer.scene)
        var cartographic = Cesium.Cartographic.fromCartesian(cartesian)
        let height = cartographic.height
        // console.log('坐标对象', {
        //   lng: Cesium.Math.toDegrees(cartographic.longitude),
        //   lat: Cesium.Math.toDegrees(cartographic.latitude),
        //   height: cartographic.height
        // })
        if (_this.darwType == '墙体') {
          console.log(cartesian, 'dikaer墙体1111')
          let point = {
            ...cartesian,
            type: 1,
            height
          }
          _this.pointsArr.push(point)
          _this.addMarker(_this.pointsArr, '墙体')
          _this.addLine(_this.pointsArr, '墙体')
          // console.log(_this.pointsArr, '_this.pointsArr2222')
          // console.log(_this.form.wallsDto, '_this.form.wallsDto33333')
        } else if (_this.darwType == '线条') {
          console.log(cartesian, 'dikaer线条2222222')
          // _this.form.linesDto.push({
          //   ...cartesian,
          //   height
          // })
          // _this.addMarker(_this.form.linesDto, '线条')
          let point = {
            ...cartesian,
            type: 2,
            height
          }
          _this.pointsArr.push(point)
          _this.addMarker(_this.pointsArr, '线条')
          _this.addLine(_this.pointsArr, '线条')
          console.log(_this.pointsArr, '_this.pointsArr2222')
          console.log(_this.form.wallsDto, '_this.form.wallsDto33333')
        }

        // let point = {
        //   ...cartesian
        // }
        // let positionObj = [
        //   Cesium.Math.toDegrees(cartographic.longitude),
        //   Cesium.Math.toDegrees(cartographic.latitude),
        //   cartographic.height
        // ]
        // pointsArr.push(...positionObj)
        // let newArr = []
        // console.log(pointsArr, '<=pointsArr  newArr=>', newArr)
        // _this.addLine(pointsArr)

        // 获取当前鼠标点击的位置
        var pick = viewer.scene.pick(movement.position)
        // 判断是否选中了实体
        if (Cesium.defined(pick) && Cesium.defined(pick.id)) {
          // 如果选中了实体，则执行自定义的操作
          console.log('你点击了实体！', pick.id.name)
        }
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK)
      //   // 左键获取坐标
      //   handler.setInputAction(function(movement) {
      //     var ray = viewer.camera.getPickRay(movement.position)
      //     var cartesian = viewer.scene.globe.pick(ray, viewer.scene)
      //     console.log(cartesian, 'dikaer')
      //     var cartographic = Cesium.Cartographic.fromCartesian(cartesian)
      //     console.log('坐标对象', {
      //       lng: Cesium.Math.toDegrees(cartographic.longitude),
      //       lat: Cesium.Math.toDegrees(cartographic.latitude),
      //       height: cartographic.height
      //     })
      //     // 获取当前鼠标点击的位置
      //     var pick = viewer.scene.pick(movement.position)
      //     // 判断是否选中了实体
      //     if (Cesium.defined(pick) && Cesium.defined(pick.id)) {
      //       // 如果选中了实体，则执行自定义的操作
      //       console.log('你点击了实体！', pick.id.name)
      //       // _this.dialogVisible = true
      //       if (pick.id.name && pick.id.name.devices.length > 0) {
      //         let deviceInfo = pick.id.name.devices[0]
      //         _this.$refs.iframeTemplateEzuikitCesium.showDialog(deviceInfo)
      //       } else if (pick.id.name && pick.id.name.devices.length == 0) {
      //         _this.$message.info('当前站点无设备，请到“系统-设备管理”添加！')
      //       }
      //       // 在此处编写实体被点击后的操作，例如弹出信息框等等
      //     }
      //   }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
    },
    getBasicInfo(item) {
      let params = {
        id: this.id
      }
      _detailModelManagement(params).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.form = JSON.parse(JSON.stringify(res.data))
          if (this.form.url) {
            this.$nextTick(() => {
              this.initViewer()
            })
          }
          this.handleTenant(item.tenantName)
          this.handleTenantChange(item.tenantId)
        }
      })
    },

    // 关键词搜索租户
    handleInput: debounce(function(event) {
      console.log(event.target.value)
      let params = { name: event.target.value }
      _queryTenants(params).then(res => {
        if (res.code == 200) {
          this.tenantArr = res.data
        }
      })
    }, 300),

    handleTenant(val) {
      let params = { name: val }
      _queryTenants(params).then(res => {
        if (res.code == 200) {
          this.tenantArr = res.data
        }
      })
    },

    // 选择租户更新项目
    handleTenantChange(val) {
      const params = {
        TenantId: val
      }
      // _listProjectStorage(params).then(res => {
      //   this.projectArr = res.data
      // })
    },

    // esc和点击旁白
    clearDrawerContent(done) {
      this.$refs['form'].resetFields()
      this.form.wallsDto = []
      this.form.linse = []
      this.pointsArr = []
      this.viewer = {}
      this.darwType = '墙体'
      done()
    },
    // 取消或者是确认
    btnClose() {
      this.$refs['form'].resetFields()
      this.form.wallsDto = []
      this.form.linse = []
      this.pointsArr = []
      this.viewer = {}
      this.darwType = '墙体'
      this.drawer = false
    },
    loadModel() {
      if (Object.keys(this.viewer).length != 0) {
        this.viewer && this.viewer.destroy()
        this.viewer = {}
      }
      this.$nextTick(() => {
        this.initViewer()
      })
    },
    add(type, proId, projectArr) {
      console.log(type, proId, projectArr)
      this.type = type
      this.projectArr = projectArr
      this.title = '新建'
      this.proId = proId
      this.handleTenant('')
      this.isView = false
      this.drawer = true
      // this.$nextTick(() => {
      //   this.initViewer()
      // })
    },
    edit(item, type, proId, projectArr) {
      this.form.wallsDto = this.form.wallsDto ?? []
      this.form.linesDto = this.form.linesDto ?? []
      console.log(this.form.wallsDto, 'this.form.wallsDto111')
      console.log(this.form.linesDto, 'this.form.linesDto222')
      this.type = type
      this.title = '编辑'
      this.projectArr = projectArr
      this.proId = proId
      this.id = item.id
      this.getBasicInfo(item)
      this.isView = false
      this.drawer = true
    },
    view(item, type, proId, projectArr) {
      this.type = type
      this.title = '详情'
      this.projectArr = projectArr
      this.proId = proId
      this.id = item.id
      this.getBasicInfo(item)
      this.isView = true
      this.drawer = true
    },
    submitForm() {
      let wallsDto = []
      let linesDto = []
      if (this.form.wallsDto.length) {
        // wallsDto = this.form.wallsDto.concat(this.form.wallsDto[0])
        wallsDto = this.form.wallsDto
      }
      // if (this.form.linesDto.length) {
      //   linesDto = this.form.linesDto.concat(this.form.linesDto[0])
      // }
      switch (this.type) {
        case 'add':
          this.$refs['form'].validate(valid => {
            if (valid) {
              let params = {
                ...this.form,
                wallsDto
                // linesDto
              }
              console.log(params, 'drawer提交的参数')
              // return
              // 调用添加项目接口
              _addModelManagement(params).then(res => {
                if (res.code == 200) {
                  this.$message.success('操作成功！')
                  this.loading = false
                  this.$emit('refreshTable')
                  this.btnClose()
                } else {
                  this.$message.error(res.message)
                }
              })
            }
          })
          break

        case 'edit':
          this.$refs['form'].validate(valid => {
            if (valid) {
              let params = {
                ...this.form
              }

              console.log(params, '编辑提交的数据=====')
              // return
              // 调用添加项目接口
              _editModelManagement(params).then(res => {
                if (res.code == 200) {
                  this.$message.success('操作成功！')
                  this.loading = false
                  this.$emit('refreshTable')
                  this.btnClose()
                } else {
                  this.$message.error(res.message)
                }
              })
            }
          })
          break

        default:
          break
      }
    },
    loadTianMap(type) {
      if (type == 0) {
        //矢量地图
        window.viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url:
              'http://{s}.tianditu.gov.cn/vec_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
              '&LAYER=vec&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
              '&style=default&format=tiles&tk=13b2f6704c077d73d7bca54018ff2e5a',
            layer: 'tdtCva',
            style: 'default',
            format: 'tiles',
            tileMatrixSetID: 'c',
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            tilingScheme: new Cesium.GeographicTilingScheme(),
            tileMatrixLabels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19'
            ],
            maximumLevel: 18
          })
        )
      } else if (type == 1) {
        //影像地图
        window.viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url:
              'http://{s}.tianditu.gov.cn/img_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
              '&LAYER=img&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
              '&style=default&format=tiles&tk=13b2f6704c077d73d7bca54018ff2e5a',
            layer: 'tdtCva',
            style: 'default',
            format: 'tiles',
            tileMatrixSetID: 'c',
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            tilingScheme: new Cesium.GeographicTilingScheme(),
            tileMatrixLabels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19'
            ],
            maximumLevel: 18
          })
        )
      } else if (type == 2) {
        //栅格地图
        window.viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url:
              'http://{s}.tianditu.gov.cn/ter_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
              '&LAYER=ter&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
              '&style=default&format=tiles&tk=13b2f6704c077d73d7bca54018ff2e5a',
            layer: 'tdtCva',
            style: 'default',
            format: 'tiles',
            tileMatrixSetID: 'c',
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            tilingScheme: new Cesium.GeographicTilingScheme(),
            tileMatrixLabels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19'
            ],
            maximumLevel: 18
          })
        )
      } else if (type == 3) {
        //标记地图
        window.viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url:
              'http://{s}.tianditu.gov.cn/cia_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
              '&LAYER=cia&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
              '&style=default&format=tiles&tk=13b2f6704c077d73d7bca54018ff2e5a',
            layer: 'tdtCva',
            style: 'default',
            format: 'tiles',
            tileMatrixSetID: 'c',
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            tilingScheme: new Cesium.GeographicTilingScheme(),
            tileMatrixLabels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19'
            ],
            maximumLevel: 18
          })
        )
      } else if (type == 4) {
        //标记地图
        window.viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url: 'https://[ t0-t7 ].tianditu.gov.cn/mapservice/swdx?tk=13b2f6704c077d73d7bca54018ff2e5a',
            layer: 'tdtCva',
            style: 'default',
            format: 'tiles',
            tileMatrixSetID: 'c',
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            tilingScheme: new Cesium.GeographicTilingScheme(),
            tileMatrixLabels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19'
            ],
            maximumLevel: 18
          })
        )
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';
@import '@/common/elementFromUI.scss';

::v-deep .el-form {
  padding-top: 20px;
}

::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

::v-deep .custom-close-button {
  width: 50px;
  height: 50px;
  background-color: red;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.map-box {
  position: relative;
  width: 100%;
  height: 78vh;
}

#cesiumContainer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.modelmag-container {
  width: 200px;
  height: 500px;
  overflow-y: auto;

  & > .item {
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  & > .item:hover {
    cursor: pointer;
    border: 1px solid #409eff;
  }
}

.selected {
  background-color: #ecf7ff;
  border: 1px solid #409eff !important;
}
</style>
